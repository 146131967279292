<template>
    <div>
        <template v-if="data[column].updateBy" >{{data[column].updateBy}} <br/></template>
        <template v-if="data[column].updateDatetime" >{{data[column].updateDatetime}} <br/></template>
        <template v-if="data[column].duration" >{{data[column].duration}} </template>
    </div>
</template>

<script>
export default {
    props: ["data", "index", "column"],
}
</script>

<style>

</style>

<template>
    <button type="button" class="btn btn-default btn-bold btn-upper btn-font-sm" @click="isMute = !isMute" v-show="isVisible">
        <i class="la" :class="(isMute) ? ' la-volume-off' : 'la-volume-up'"></i>
    </button>
</template>

<script>
import audioUrl from '@/assets/notification/highbell.mp3'
const audio = new Audio(audioUrl);
import SweetAlertTemplate from "./SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
 data() {
     return {
         isMute: false,
     }
 },
 props: {
     mute: { type: Boolean, default: false },
     isVisible: {type: Boolean, default: true}
 },
 methods: {
     ring:function(checkSound = false) {
         if(!this.isMute){
            audio.volume = (checkSound) ?  0.0 : 1.0;
            audio.play().catch(function(error) {
                if(error.message.match(/user didn't interact/gi) != null){
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Click OK untuk mengaktifkan notifikasi bunyi",
                        swalText: "",
                        swalType: "info",
                        onConfirmButton: function () {
                        }
                    });
                }
            });;

         }
     }
 },
 mounted () {
    this.isMute = this.mute;
    this.ring(true);
 },
}
</script>

<style>

</style>

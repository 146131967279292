export default {
    NEW: {
        title: "Baru",
        class: "kt-badge--brand",
        enum: "NEW"
    },
    CONFIRM: {
        title: "Terkonfirmasi",
        class: "kt-badge--success",
        enum: "CONFIRM"
    },
    ARRIVED: {
        title: "Pasien Datang",
        class: "kt-badge--primary",
        enum: "ARRIVED"
    },
    DISPATCH: {
        title: "Pasien Selesai",
        class: "kt-badge--success",
        enum: "DISPATCH"
    },
    CANCEL: {
        title: "Dibatalkan",
        class: "kt-badge--danger",
        enum: 'CANCEL'
    }
}
